<template>
    <el-date-picker
        v-model="model"
        :type="type"
        :value-format="format"
        :format="format"
        :range-separator="separator"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        :size="size">
    </el-date-picker>
</template>
<script>
export default {
    name: "XDateBetween",
    computed: {
        model: {
            get() {
                return [this.start, this.end];
            },
            set(newVal, oldVal) {
                if (Array.isArray(newVal) && newVal.length === 2) {
                    this.$emit("update:start", newVal[0]);
                    this.$emit("update:end", newVal[1]);
                } else {
                    this.$emit("update:start", '');
                    this.$emit("update:end", '');
                }
            }
        },
        format() {
            switch (this.type) {
                case 'datetimerange':
                    return 'yyyy-MM-dd HH:mm:ss';
                case 'monthrange':
                    return 'yyyy-MM';
                case 'daterange':
                default:
                    return 'yyyy-MM-dd';
            }
        }
    },
    props: {
        start: String,
        end: String,
        type: {
            type: String, // datetimerange daterange monthrange
            default: "daterange"
        },
        size: {
            type: String,
            default: "small"
        },
        separator: {
            type: String,
            default: "至"
        }
    }
}
</script>

<style scoped>
</style>
