<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="时间" textWidth="450px">
                <x-date-between separator="~" :start.sync="search.xiaoFeiSJBegin" :end.sync="search.xiaoFeiSJEnd"
                                type="daterange" size="small"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border :header-cell-style="{'text-align':'center'}"
                  :cell-style="{'text-align':'center'}">
            <el-table-column width="100px" label="门店" prop="menDianMC">
            </el-table-column>
            <el-table-column label="净销售额" prop="jxse">
            </el-table-column>
            <el-table-column label="销售额" prop="xse">
            </el-table-column>
            <el-table-column label="销售笔数" prop="xsbs">
            </el-table-column>
            <el-table-column label="退款金额" prop="tkje">
            </el-table-column>
            <el-table-column label="退款笔数" prop="tkbs">
            </el-table-column>

            <el-table-column label="微信支付">
                <el-table-column label="销售金额" prop="wxxsje">
                </el-table-column>
                <el-table-column label="销售笔数" prop="wxxsbs">
                </el-table-column>
                <el-table-column label="退款金额" prop="wxtkje">
                </el-table-column>
                <el-table-column label="退款笔数" prop="wxtkbs">
                </el-table-column>
            </el-table-column>

            <el-table-column label="会员卡支付">
                <el-table-column label="销售金额" prop="hyxsje">
                </el-table-column>
                <el-table-column label="销售笔数" prop="hyxsbs">
                </el-table-column>
                <el-table-column label="退款金额" prop="hytkje">
                </el-table-column>
                <el-table-column label="退款笔数" prop="hytkbs">
                </el-table-column>
            </el-table-column>

            <el-table-column label="支付宝支付">
                <el-table-column label="销售金额" prop="zfbxsje">
                </el-table-column>
                <el-table-column label="销售笔数" prop="zfbxsbs">
                </el-table-column>
                <el-table-column label="退款金额" prop="zfbtkje">
                </el-table-column>
                <el-table-column label="退款笔数" prop="zfbtkbs">
                </el-table-column>
            </el-table-column>

            <el-table-column label="现金支付">
                <el-table-column label="销售金额" prop="xjxsje">
                </el-table-column>
                <el-table-column label="销售笔数" prop="xjxsbs">
                </el-table-column>
                <el-table-column label="退款金额" prop="xjtkje">
                </el-table-column>
                <el-table-column label="退款笔数" prop="xjtkbs">
                </el-table-column>
            </el-table-column>

            <el-table-column label="其他支付">
                <el-table-column label="销售金额" prop="qtxsje">
                </el-table-column>
                <el-table-column label="销售笔数" prop="qtxsbs">
                </el-table-column>
                <el-table-column label="退款金额" prop="qttkje">
                </el-table-column>
                <el-table-column label="退款笔数" prop="qttkbs">
                </el-table-column>
            </el-table-column>

            <el-table-column label="会员充值">

                <el-table-column label="店内">
                    <el-table-column label="线上充值" prop="xscz">
                    </el-table-column>
                    <el-table-column label="现金充值" prop="xjcz">
                    </el-table-column>
                </el-table-column>

                <el-table-column label="门店后台赠送" prop="mdhtzs">
                </el-table-column>
                <el-table-column label="门店后台扣除" prop="mdhtkc">
                </el-table-column>
                <el-table-column label="收银员扣除" prop="syysydkc">
                </el-table-column>
                <!--<el-table-column label="商户退款" prop="shtk">-->
                <!--</el-table-column>-->
                <!--<el-table-column label="商户后台充值" prop="shhtcz">-->
                <!--</el-table-column>-->
                <!--<el-table-column label="商户后台赠送" prop="shhtzs">-->
                <!--</el-table-column>-->
                <!--<el-table-column label="商户扣除" prop=" shkc">-->
                <!--</el-table-column>-->
                <el-table-column label="门店会员余额退款" prop="mdhyyetk">
                </el-table-column>
                <el-table-column label="收银员余额退款" prop="syyyetk">
                </el-table-column>
                <el-table-column label="实际充值金额" prop="sjczje">
                </el-table-column>
                <el-table-column label="总赠送金额" prop="zzsje">
                </el-table-column>
                <el-table-column label="总扣除金额" prop="zkcje">
                </el-table-column>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, prev, pager, next, jumper"/>
    </div>
</template>
<script>
    import * as service from "@/service/jygl/ShouKuanMX";
    import XTableBase from "@/components/x/XTableBase";
    import XDateBetween from "@/components/x/sc/XDateBetween.vue";

    export default {
        name: "XiaoShouTongJiList",
        mixins: [XTableBase],
        components: {XDateBetween},
        data() {
            this.refreshService = service.getMdXiaoShouTjList;
            this.service = service;
            return {
                options: [],
                ceshi: false,
                search: {
                    xiaoFeiSJBegin: '',
                    xiaoFeiSJEnd: "",
                },
            }
        },
    }
</script>

<style scoped>
</style>
